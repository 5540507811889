// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/prelude/v4.1.1/src/Control/Applicative.purs");
var Pages_Navigation = require("/home/runner/work/jackpooley.io/jackpooley.io/app/Components/Navigation.purs");
var React_Basic = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic.purs");
var React_Basic_DOM = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM.purs");
var React_Basic_DOM_Generated = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic/v13.0.0/src/React/Basic/DOM/Generated.purs");
var React_Basic_Hooks = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks.purs");
var React_Basic_Hooks_Internal = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/react-basic-hooks/v4.2.2/src/React/Basic/Hooks/Internal.purs");
var Type_Equality = require("/home/runner/work/jackpooley.io/jackpooley.io/.spago/type-equality/v3.0.0/src/Type/Equality.purs");
var mkNotFound = (function () {
    var render = function (slots) {
        return function (props) {
            return React_Basic.fragment([ React_Basic.element(slots.navigation)({}), React_Basic_DOM_Generated.div()({
                className: "max-w-5xl flex mx-auto my-12",
                children: [ React_Basic_DOM_Generated.div()({
                    className: "mr-1 text-gray-100 w-3/5 rounded-l-lg rounded-r-none shadow-2xl bg-gray-800 p-12 text-left",
                    children: [ React_Basic_DOM_Generated.h1()({
                        className: "text-3xl font-bold",
                        children: [ React_Basic_DOM.text("NotFound") ]
                    }), React_Basic_DOM_Generated.div()({
                        className: "w-4/5 pt-3 border-b-2 border-teal-500"
                    }) ]
                }), React_Basic_DOM_Generated.div()({
                    className: "w-2/5",
                    children: [ React_Basic_DOM_Generated.img()({
                        className: "shadow-2xl",
                        src: "https://source.unsplash.com/lXFAF-8wnNs"
                    }) ]
                }) ]
            }) ]);
        };
    };
    return function __do() {
        var navigation = Pages_Navigation.mkNavigation();
        return React_Basic_Hooks.component()()()("NotFound")(function (props) {
            return Control_Applicative.pure(React_Basic_Hooks_Internal.applicativeRender(Type_Equality.refl))(render({
                navigation: navigation
            })(props));
        })();
    };
})();
module.exports = {
    mkNotFound: mkNotFound
};

